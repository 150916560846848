import { Color3, Node, StandardMaterial, TransformNode } from "@babylonjs/core";

/**
 * This represents a script that is attached to a node in the editor.
 * Available nodes are:
 *      - Meshes
 *      - Lights
 *      - Cameas
 *      - Transform nodes
 * 
 * You can extend the desired class according to the node type.
 * Example:
 *      export default class MyMesh extends Mesh {
 *          public onUpdate(): void {
 *              this.rotation.y += 0.04;
 *          }
 *      }
 * The function "onInitialize" is called immediately after the constructor is called.
 * The functions "onStart" and "onUpdate" are called automatically.
 */
export default class MyScript extends TransformNode {


    

    /**
     * Override constructor.
     * @warn do not fill.
     */
    // @ts-ignore ignoring the super call as we don't want to re-init
    protected constructor() { }

    /**
     * Called on the node is being initialized.
     * This function is called immediatly after the constructor has been called.
     */
    public onInitialize(): void {
        console.log("lack.ts init");
        let material = new StandardMaterial("mat", this.getScene());
         material.alpha = 1;
         material.diffuseColor = new Color3(1,0,0);
         material.specularColor = new Color3(0.5, 0.6, 0.87);
    material.emissiveColor = new Color3(1, 1, 1);
    material.ambientColor = new Color3(0.23, 0.98, 0.53);
    //this.material.diffuseTexture = new Texture("/assets/textures/sun.jpg", this);
        window.addEventListener('message', (event) => {
            console.log(`Received message: ${event.data}`);
            console.log(this);
            for (var i = 0; i < this.getChildMeshes().length; i++) {

                if(this.getChildMeshes()[i].material) {
                    
                    if(this.getChildMeshes()[i].material.name.indexOf('Lack') != -1) {
                        this.getChildMeshes()[i].material = material;
                    }
                    
                }
                        
                    
                

            }

        });
    }

    /**
     * Called on the scene starts.
     */
    public onStart(): void {
        // ...
    }

    /**
     * Called each frame.
     */
    public onUpdate(): void {
        // ...
    }

    /**
     * Called on a message has been received and sent from a graph.
     * @param message defines the name of the message sent from the graph.
     * @param data defines the data sent in the message.
     * @param sender defines the reference to the graph class that sent the message.
     */
    public onMessage(name: string, data: any, sender: any): void {
        switch (name) {
            case "myMessage":
                // Do something...
                break;
        }
    }
}
